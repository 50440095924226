import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Password from "src/components/Password";
import ChoosePlan from "src/components/choose-plan";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "src/components/hook-form";
import Translate from "src/components/translate";
import GoogleOAuthButton from "src/pages/auth/components/google-login";
import { PATH_AUTH } from "src/routes/paths";
import AuthHelper from "./components/authHelper";
import EnterOtp from "./components/enter-otp";
import ErrorBanner from "./components/error-banner";
import useLogin from "./hooks/use-login";
import { useEffect, useMemo, useRef, useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useNetwork } from "wagmi";
import { useAccount, useDisconnect } from "wagmi";
import { HOST_API } from "src/config";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { setSession } from "src/utils/jwt";
import useAuth from "src/hooks/useAuth";
import useGetCurrency from "src/layouts/shared/header/components/currency-popover/hooks/use-get-currency";
import "./style.css";
import { capitalCase } from "change-case";

const LoginForm = ({ adminLogin }) => {
  const { onSubmit, methods } = useLogin(adminLogin);
  const [error, seError] = useState(false);
  const navigate = useNavigate();
  const {
    watch,
    setValue,
    setError,
    formState: { isSubmitting, errors },
  } = methods;

  const data = watch();

  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const { i18n } = useTranslation();
  const { getUser } = useAuth();
  const { uname } = useParams();
  const fetchCurrency = useGetCurrency();
  const Login = async () => {
    const formData = new FormData();
    if (uname) {
      formData.append("referral", uname);
      formData.append("is_register", 1);
    } else {
      formData.append("is_register", 0);
    }
    formData.append("wallet_address", address);

    try {
      const BASE_URL = `${HOST_API}api/user-register`;
      const { status, data } = await axios.post(BASE_URL, formData, {
        headers: {
          "Accept-Language": i18n.language,
        },
      });
      if (status === 200) {
        const {
          access_token,
          user,
          message,
          secret,
          menu_lists,
          package_status,
        } = data;
        localStorage.setItem("menu", JSON.stringify(menu_lists));
        await getUser();
        fetchCurrency();
        if (!secret) {
          localStorage.setItem("menu", JSON.stringify(menu_lists));
          localStorage.setItem("isAdmin", Boolean(user.is_super_admin));
          localStorage.setItem("isSubAdmin", Boolean(user.is_sub_admin));
          localStorage.setItem("package_status", Boolean(package_status));
          setSession(access_token);
          // navigate("/user/dashboard");
          // if (uname) { // }

          if (uname) {
            navigate("/user/dashboard");
          }

          if (Boolean(user.is_sub_admin)) {
            // setPlan(reqObj.plan);
            const [menu] = menu_lists;
            const { path, children } = menu?.items[0] || {};
            if (path.includes("dashboard")) {
              window.location = children[0]?.path;
              return;
            }
            window.location = path;
            return;
          }

          return {
            status: true,
            data: user.username,
            isAdmin: Boolean(user.is_super_admin),
            isSubAdmin: Boolean(user.is_sub_admin),
          };
        } else if (secret) {
          return { status: false, secret };
        } else {
          return { status: false, message };
        }
      }
    } catch (err) {
      Object.entries(err.response.data.errors).forEach(([k, v]) =>
        setError(k, { message: v[0] })
      );
      // return { status: false, message: err.response.data };
      seError(true);
    }
  };
  useEffect(() => {
    if (isConnected && address && !adminLogin) {
      methods.setValue("wallet_address", address);
      Login();
    } else {
      methods.reset();
    }
  }, [address, isConnected]);

  return (
    <Box sx={{ justifyContent: "flex-start", display: "flex" }}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={2} mb={2}>
          <AuthHelper />
          {!adminLogin && error && (
            <Alert severity="error">{errors?.referral?.message}</Alert>
          )}
          {adminLogin && <ErrorBanner />}
        </Stack>

        <Box>
          <Stack spacing={2}>
            {adminLogin ? (
              <>
                <RHFTextField name="email" label="Email address" />
                <Password name="password" label="register.password" />
              </>
            ) : (
              <RHFTextField
                disabled
                name="wallet_address"
                label="Wallet address"
              />
            )}

            <ChoosePlan />
          </Stack>
          {uname && (
            <Stack direction="row" alignItems="flex-start" sx={{ mt: 2 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography sx={{ textAlign: "left", fontSize: "13px" }}>
                  Become Part of The Team, Register Under{" "}
                  <span style={{ color: "#2683c0", fontWeight: "bold" }}>
                    {uname || ""}
                  </span>{" "}
                  Now!
                </Typography>
              </Box>
            </Stack>
          )}

          <Stack direction="row" alignItems="flex-start" sx={{ mt: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                sx={{ textAlign: "left", fontSize: "14px", color: "gray" }}
              >
                <Translate>
                  By registering on XGPT Cloud MLM demo, you agree to our Terms
                  & Conditions and Privacy Policy. Please review them before
                  proceeding with your registration.
                </Translate>
              </Typography>
            </Box>
          </Stack>
          {!adminLogin ? (
            <>
              <Box
                sx={{
                  p: 3,
                  mb: -3,
                  ml: -3,
                }}
              >
                {" "}
                <w3m-button />
                {/* <p>{chain?.name}</p> */}
              </Box>
            </>
          ) : (
            <LoadingButton
              sx={{ mt: 3 }}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              name="login"
            >
              <Translate>register.login</Translate>
            </LoadingButton>
          )}
          {/* <GoogleOAuthButton
            plan={methods.getValues("plan")}
            buttonLabel="register.google_login"
          /> */}
        </Box>
      </FormProvider>
      <EnterOtp data={data} />
    </Box>
  );
};

export default LoginForm;
